import { BaseApiRequest } from "./BaseApi";

function logMessage(level, message, error, properties) {
  let requestObj = [
    {
      level: level,
      renderedMessage: message,
      timestamp: new Date(),
      exception: JSON.stringify(error),
      properties: properties
    },
  ];

  callApi(requestObj).catch((err) => {
    console.log(err);
  });
}

const callApi = (requestObj) => {
  return BaseApiRequest(null, `/serilogging`, "logging", "POST", requestObj, {
    'Content-type': "application/json",
    'LoggingHostName': "GPRLive-UI"
  });
}

function logInfo(message, error, properties) {
  logMessage("Information", message, error, properties);
}
  
function logEvent(message, error, properties) {
  logMessage("Event", message, error, properties);
}
  
function logError(error, message, properties) {
  logMessage("Error", message ?? "Error was thrown", error, properties);
}
  
function logWarn(message, error, properties) {
  logMessage("Warning", message, error, properties);
}

export { logEvent, logError, logInfo, logMessage, logWarn };