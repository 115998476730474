import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, TextField } from '@esgian/esgianui';
import { RigSelect } from '@components/Inputs';
import { getLookupRigs } from '@store/features';
import { useSelector } from 'react-redux';
import { BASELINE_TYPE, DATE_FORMAT } from '@constants';
import TargetDetails from '@components/Inputs/BaselineInput/BaselineModal/TargetDetails';
import moment from 'moment-timezone';
import DateRangeDropdown from '@components/DateRangeDropdown';

function BaselineDetails({ baseline, setBaseline, highlightErrors }) {
  const lookupRigs = useSelector(getLookupRigs);

  const handleChange = useCallback(
    (val) => {
      setBaseline((prev) => ({ ...prev, ...val }));
    },
    [setBaseline]
  );

  const currentRig = useMemo(() => {
    return lookupRigs?.find(({ rigId }) => rigId === baseline.rigId) || null;
  }, [baseline.rigId, lookupRigs]);

  const setSuggestedName = useCallback(() => {
    const { startDate, endDate, well } = baseline;
    let name = '';
    if (currentRig) {
      name += currentRig?.rigName;
    }
    if (well) {
      name += ` -  ${well.wellname}`;
    } else {
      if (startDate && endDate) {
        name += ` - ${moment(startDate, DATE_FORMAT).format(DATE_FORMAT)} - ${moment(
          endDate,
          DATE_FORMAT
        ).format(DATE_FORMAT)}`;
      }
    }
    handleChange({ baselineName: name });
  }, [baseline, currentRig]);

  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={3}>
        <RigSelect
          error={highlightErrors && !baseline.rigId}
          helperText={highlightErrors && !baseline.rigId ? 'Required' : ''}
          selectedRig={currentRig}
          onChange={(rig) => handleChange({ rigId: rig.rigId })}
        />
        <DateRangeDropdown
          endDate={baseline?.endDate ?? null}
          startDate={baseline?.startDate ?? null}
          selectedDateName=""
          onSave={(val) => handleChange(val)}
          fullWidth
          helpertext={'Required'}
          error={highlightErrors && (!baseline.startDate || !baseline.endDate)}
          well={baseline.well}
          allowTimeZoneChange={false}
          currentRig={currentRig || null}
          preferredValue={baseline.name}
          disableSelectedRig={true}
        />
        {baseline?.baselineTypeId === BASELINE_TYPE.TARGET && (
          <TargetDetails
            highlightErrors={highlightErrors}
            baseline={baseline}
            setBaseline={setBaseline}
          />
        )}
        <TextField
          helperText={highlightErrors && !baseline.baselineName ? 'Please fill this field.' : ''}
          error={highlightErrors && !baseline.baselineName}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            sx: { textOverflow: 'ellipsis', pt: '12px', pb: '12px' }
          }}
          InputProps={{
            sx: { paddingRight: 0 },
            endAdornment: (
              <Button
                onClick={setSuggestedName}
                sx={{ width: '275px' }}
                variant={'text'}
                size={'small'}>
                use suggested name
              </Button>
            )
          }}
          placeholder={'Create a baseline name'}
          label={'Name'}
          value={baseline.baselineName ?? ''}
          onChange={({ target }) => handleChange({ baselineName: target.value })}
        />
      </Stack>
    </Box>
  );
}

BaselineDetails.propTypes = {
  baseline: PropTypes.object.isRequired,
  setBaseline: PropTypes.func.isRequired,
  highlightErrors: PropTypes.bool
};

BaselineDetails.defaultProps = {
  highlightErrors: false
};

export default BaselineDetails;
