export const LOOKUP_EQUIPMENT = [
  { value: 1, name: 'HPU', key: 'HPU' },
  { value: 2, name: 'Topdrive', key: 'TD' },
  { value: 3, name: 'Drawworks', key: 'DW' },
  { value: 4, name: 'MudPumps', key: 'MP' },
  { value: 5, name: 'Water treatment', key: 'WaterTreatment' },
  { value: 6, name: 'Lighting', key: 'Lighting' },
  { value: 7, name: 'Air', key: 'Air' },
  { value: 8, name: 'HVAC', key: 'HVAC' },
  { value: 9, name: 'Water cooling', key: 'WaterCooling' },
  { value: 10, name: 'Galley', key: 'Gallery' },
  { value: 11, name: 'Cementing', key: 'Cementing' },
  { value: 12, name: 'Pipe handling', key: 'PipeHandling' }
];
export const LOOKUP_DRILLING_ACTIVITY = [
  { value: 1, name: 'Abandonment' },
  { value: 2, name: 'Activity data missing' },
  { value: 3, name: 'Cementing' },
  { value: 4, name: 'Condition mud & circulating' },
  { value: 5, name: 'Completion' },
  { value: 6, name: 'Coring' },
  { value: 7, name: 'Drilling' },
  { value: 8, name: 'Mechanical Plug & Whipstock' },
  { value: 9, name: 'Nipple B.O.P.' },
  { value: 10, name: 'Non Productive Time' },
  { value: 11, name: 'Other' },
  { value: 12, name: 'Pressure Test Hole' },
  { value: 13, name: 'Reaming' },
  { value: 14, name: 'Rig Downtime' },
  { value: 15, name: 'Rig Move Jack Up' },
  { value: 16, name: 'Rig off Contract' },
  { value: 17, name: 'Run casing' },
  { value: 18, name: 'Safety' },
  { value: 19, name: 'Standby' },
  { value: 20, name: 'Service Rig' },
  { value: 21, name: 'Test Well Control Equipment' },
  { value: 22, name: 'Tripping' },
  { value: 23, name: 'Well Control' },
  { value: 24, name: 'Wireline, Coiled Tubing & Logging' }
];

export const LOOKUP_OPERATIONS = [
  { value: 1, name: 'Drilling', key: 'Drilling' },
  { value: 2, name: 'Hotel & Utilities', key: 'Accom' }
];
export const LOOKUP_FOCUS_AREA = [
  { value: 1, name: 'Too many engines running', key: 'TooManyEng' },
  { value: 2, name: 'Idle Mud pumps', key: 'MP_AUX_Idle' },
  { value: 3, name: 'Too many HPU pumps online', key: 'TooManyHPU' },
  { value: 4, name: 'Idle Drawworks', key: 'DW_AUX_Idle' },
  { value: 5, name: 'Idle Top drive', key: 'TD_AUX_Idle' }
];
