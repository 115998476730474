import { BaseApiRequest } from './BaseApi';
import cryptoJs from 'crypto-js';
import { getToken } from '@store/helpers';
import { logInfo } from './Logging';

export const verifyTokenValid = (signal, token) => {
  return BaseApiRequest(signal, `/profile?token=${getToken() || token}`, 'auth');
};

export const loginUser = (signal, username, password) => {
  const key = cryptoJs.enc.Utf8.parse('9754445415798101');
  const iv = cryptoJs.enc.Utf8.parse('9754445415798101');
  const hashedPassword = cryptoJs.AES.encrypt(cryptoJs.enc.Utf8.parse(password), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.Pkcs7
  }).toString();
  const body = {
    username: username,
    password: hashedPassword,
    project: 'values',
    tokenType: 'web'
  };
  logInfo(`Logging in following user: ${username}`, undefined, {
    username: username,
  });
  return BaseApiRequest(signal, `/login`, 'auth', 'POST', body);
};

export const logoutUser = (token) => {
  return BaseApiRequest(null, `/logout?token=${token}`, 'auth', 'DELETE');
};
